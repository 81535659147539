<template>
    <div id="pageTable">
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>    
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <v-breadcrumbs :items="itemsLinks">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12">
                    <simple-table
                        ref="tablageneral"
                        :tableName="'Detalle precálculo de nómina'"
                        :columns="columns"
                        :perPage="[10,25,50,100]"
                        :isSelectable="true"
                        @selectAllEvent="selectAll"
                        :rows="itemsEmpleadosTabla"
                        :loading="isLoading"
                        :valCheck="'empleado_id'"
                    >
                        <template slot="actionButtonsLeft">
                            <v-menu buttom rounded offset-y content-class="menuClassAcciones" class="elevation-0 d-inline ml-2"> 
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        color="white"
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="!masivo"
                                        rounded
                                        class="btn-agrupar-por justify-space-between elevation-0 mr-2 pr-2"
                                    >
                                        Acciones
                                        <v-icon>keyboard_arrow_down</v-icon>
                                    </v-btn>
                                </template>

                                <v-list class="py-0">
                                    <v-list-item
                                        class="py-0 px-8"
                                        @click="recalcular(null, 'empleados')"
                                    >   
                                        <v-list-item-title>Recalcular nómina</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        class="py-0 px-8"
                                        @click="eliminar(null, 'empleados')"
                                    >   
                                        <v-list-item-title>Eliminar nómina</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item" v-for="item in data">
                                <td style="width:115px !important; ">
                                    <div >
                                        <v-checkbox class="mb-5" primary hide-details :value="item.empleado_id" v-model="selected_general" ></v-checkbox>
                                    </div>
                                </td>
                                <td><div>{{item.empleado.nombre_completo}}</div></td>
                                <td><div>{{formatoMoneda(item.total_percepciones)}}</div></td>
                                <td><div>{{formatoMoneda(item.total_deducciones)}}</div></td>
                                <td><div>{{formatoMoneda(item.neto_pagar)}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" @click="consultar(item)"  class="botonHover" icon fab dark small>
                                                    <v-icon class="tamIconoBoton iconoDelgadoBoton" v-text="'$remove_red_eye_outline'"></v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Ver</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="recalcular(item, 'empleado')">
                                                    <v-icon class="tamIconoBoton iconoDelgadoBoton">redo</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Recalcular</span>
                                        </v-tooltip> 
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item, 'empleado')">
                                                    <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                </v-col>
            </v-row>
        </v-container>
        <template class="borde-card">
            <v-dialog v-model="dialogCalculoTabla" persistent max-width="700px" scrollable class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalCalculoTabla"></v-btn>
                </template>
                
                    <v-card class="borde-card">
                        <perfect-scrollbar>
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagenCalculoTabla" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModalCalculoTabla }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text >
                                <div id="padre" v-show="isLoadingCalculoTabla">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingCalculoTabla" grid-list-md id="contenedor">
                                    <v-row class="pa-0 ma-0"> 
                                        <simple-table
                                            class="tablaWidth"
                                            :rows="itemsEmpleados"
                                            :columns="headersTableEmpleados"
                                            :perPage="[10, 25, 50, 100]"
                                            shortPagination
                                            :showPagination="false"
                                            :modalTable="true"
                                        >
                                            <tbody class="tbl-incidencias" slot="body" slot-scope="{ data }">
                                                <tr :key="item.id" v-for="(item, index) in data">
                                                    <td class="pa-0">
                                                        <expandable-item :image="urlAvatar + item.empleado.foto" :title="item.empleado.nombre_completo" :valid="item.nomina_valida">
                                                            <template slot="content">
                                                                <table class="tbl-child" width="100%">
                                                                    <tbody>
                                                                        <template>
                                                                            <tr>
                                                                                <td class="pt-4"><p class="subtitle">Días del periodo: </p></td>
                                                                                <td class="pt-4"><p class="text">{{item.empleado.dias_periodo}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Días de pago: </p></td>
                                                                                <td><p class="text">{{redondearNumero(item.empleado.dias_de_pago)}}</p></td>
                                                                            </tr>
                                                                            <tr v-if="item.empleado.dias_vacaciones != 0">
                                                                                <td><p class="subtitle">Días de vacaciones: </p></td>
                                                                                <td><p class="text">{{item.empleado.dias_vacaciones}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Sueldo diario: </p></td>
                                                                                <td><p class="text">{{formatNum(item.empleado.salario_diario)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Faltas: </p></td>
                                                                                <td><p class="text">{{item.empleado.faltas}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Incapacidades: </p></td>
                                                                                <td><p class="text">{{item.empleado.incapacidades}}</p></td>
                                                                            </tr>
                                                                            <template v-if="item.itemsPercepciones.length != 0">
                                                                                <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                <tr>
                                                                                    <td class="pb-4" style="color: #1E2245; font-weight: bold;">Percepciones:</td>
                                                                                </tr>
                                                                                <tr v-for="itemIn in item.itemsPercepciones" :key="itemIn.id">
                                                                                    <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                    <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                </tr>
                                                                                <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                            </template>

                                                                            <template v-if="item.itemsDeducciones.length != 0">
                                                                                <tr v-if="item.itemsPercepciones.length == 0"><td colspan="2"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                <tr>
                                                                                    <td class="pb-4" style="color: #1E2245; font-weight: bold;">Deducciones:</td>
                                                                                </tr>
                                                                                <tr v-for="itemIn in item.itemsDeducciones" :key="itemIn.id">
                                                                                    <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                    <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                </tr>
                                                                                <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                            </template>

                                                                            <template v-if="item.itemsOtrosPagos.length != 0">
                                                                                <tr v-if="item.itemsDeducciones.length == 0 && item.itemsPercepciones.length == 0"><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                                <tr>
                                                                                    <td class="pb-4" style="color: #1E2245; font-weight: bold;">Otros pagos:</td>
                                                                                </tr>
                                                                                <tr v-for="itemIn in item.itemsOtrosPagos" :key="itemIn.id">
                                                                                    <td><p class="subtitle">{{itemIn.nombre}}</p></td>
                                                                                    <td><p class="text">{{formatNum(itemIn.total)}}</p></td>
                                                                                </tr>
                                                                                <tr><td colspan="2" style="padding-left:0px !important;"><v-divider class="my-4" style="border: 1px solid #EFF1FB; width:100%;"></v-divider></td></tr>
                                                                            </template>
                                                                            <tr>
                                                                                <td><p class="subtitle">Total percepciones: </p></td>
                                                                                <td><p class="text">{{formatNum(item.total_percepciones)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Total deducciones: </p></td>
                                                                                <td><p class="text">{{formatNum(item.total_deducciones)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Total otros pagos: </p></td>
                                                                                <td><p class="text">{{formatNum(item.otros_ingresos)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Percepciones exentas: </p></td>
                                                                                <td><p class="text">{{formatNum(item.per_excentas)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><p class="subtitle">Percepciones gravadas: </p></td>
                                                                                <td><p class="text">{{formatNum(item.per_grabado)}}</p></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="py-4" style="color: #1E2245; font-weight: bold;">Neto a recibir:</td>
                                                                                <td class="py-4 textNeto" style="color: #1E2245; font-weight: bold;">{{formatNum(item.neto_pagar)}}</td>
                                                                            </tr> 
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                            </template>
                                                        </expandable-item>

                                                        <hr style="border: 1.5px solid #F2F2F2;" v-if="index < data.length - 1" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </simple-table>
                                    </v-row>  
                                </v-container>
                            </v-card-text>
                        </perfect-scrollbar>
                        <v-card-actions>
                            <v-layout row>
                                <v-flex class="justify-center">
                                    <div class="btnModal">
                                        <button class="btnCerrar modal-pull-right" @click="cerrarModalTabla()">Cerrar</button>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import Vue from 'vue';
import Datatable from '@/components/datatable/Datatable.vue';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import ExpandableItem from '@/components/ExpandableItem.vue';
import Notify from '@/plugins/notify';
import apiCalculoNomina from '@/api/nominas/calculoNomina';
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    components: {
        'data-table': Datatable,
        "simple-table": DatatableSimple,
        ExpandableItem,
    },
    data() {
        return {
            url         : "nominas-timbres/detalle",
            columns     : [
                {
                    label: 'Empleado',
                    name: 'nombre_completo',
                    filterable: false
                },
                {
                    label: 'Total percepciones',
                    name: 'total_percepciones',
                    filterable: false
                },
                {
                    label: 'Total deducciones',
                    name: 'total_deducciones',
                    filterable: false
                },
                {
                    label: 'Neto a pagar',
                    name: 'neto_pagar',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            itemsLinks      : [
                {
                    text: 'Precálculo de nómina',
                    disabled: false,
                    to: '/calculoNomina',
                },
                {
                    text: 'Detalle del precálculo de nómina',
                    disabled: true,
                    to: '/detallePrecalculo',
                },
            ],
            filters     : {
                activo    :true,
                paginate  :true,
                order     :"asc",
                nomina_id : null
            },
            userData                : this.$session.get('usuario'),
            accion                  : null,
            tituloModal             : null,
            dialog                  : false,
            isLoading               : false,
            isSaving                : false,
            imagen                  : "",
            nomina                  : null,
            checkAll                : false,
            selected_general        : [],
            selected_general_tabla  : [],
            unselected_general      : [],
            masivo                  : false,
            masivo_tabla            : false,
            loadingLottie           : false,
            fraseLoading            : 'Timbrando',
            dialogCalculoTabla      : false,
            isLoadingCalculoTabla   : false,
            itemsEmpleados          : [],
            headersTableEmpleados   : [
                { label: "Empleados", align:'center',   name: "nombre_completo",    filterable: false },
            ],
            urlAvatar               : '',
            imagenCalculoTabla      : '/static/icon/consultar-calculo.png',
            tituloModalCalculoTabla : 'Resultados de la consulta',
            itemsEmpleadosTabla     : [],

        }
    },
    watch:{
        selected_general:function(value) {
            if(value.length > 0 ){
                this.masivo =  true;
            }
            else{
                this.masivo =  false;
            }
        },
    },
    methods: {
        abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;
            this.isLoading  = false;
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
           
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        guardar() {
            
        },
        eliminar(data, accion){

            let mensaje = '';
            let param = {};
            if(accion === 'empleado'){
                mensaje = "¿Estás seguro que deseas eliminar el empleado "+data.empleado.nombre_completo+"?";
                param = { nomina_id: this.nomina.id,empleados: [data.empleado_id]}
            }
            else{
                mensaje = "¿Estás seguro que deseas eliminar a los empleados seleccionados?"
                param = { nomina_id: this.nomina.id,empleados: this.selected_general}
            }

            Notify.Alert(
                "ADVERTENCIA",
                mensaje,
                "Eliminar",
                () => {
                    this.fraseLoading = "Eliminando";
                    this.loadingLottie = true;
                    apiCalculoNomina.eliminarEmpleadoPrecalculo(param).then(async response => {
                        this.loadingLottie=false; 
                        await this.getEmpleados(this.nomina.id);
                        Notify.Success("Operación exitosa", "La petición se realizo satisfactoriamente.");
                        this.selected_general=[];
                       
                    })
                    .catch(err => {
                        this.loadingLottie=false; 
                        this.selected_general=[];
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                        this.$refs.tabla.unselectAll();
                    });
                }
            )
        },
        /**
         * @method formatoMoneda Este método sirve para formatear el valor a formato moneda en la tabla principal.
         * @description Este método sirve para formatear el valor a formato moneda en la tabla principal.
         */
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },
        /**
         * @method selectAll Este método se realiza al dar clic en el check que esta en el header de la tabla principal.
         * @description Este método se realiza al dar clic en el check que esta en el header de la tabla principal. Se selecciones todos los check o se deseleccionan.
         * Se ejecuta con una emit del componente tabla.
         * @param {boolean} isSelected variable para saber si check se esta seleccionado o no.
         * @param {object} items variable donde vienen todos los items de los empledos.
         */
        selectAll(isSelected, items) {
            this.selected_general = items;
        },

        async recalcular(item, emp){
            let self = this;
            let mensaje = '';
            let param = {};
            if(emp === 'empleado'){
                mensaje = "¿Estás seguro que deseas eliminar el empleado "+item.empleado.nombre_completo+"?";
                param = { id : this.nomina.id, accion: 'RECALCULAR', tipo_reporte: "empleados", tipo_nomina : 'GENERAL', empleados : [{empleado_id: item.empleado_id}], descargar:true }
            }
            else{
                let empleados = [];
                this.selected_general.forEach((element) => {
                    empleados.push({empleado_id: element});
                });
                mensaje = "¿Estás seguro que deseas eliminar a los empleados seleccionados?"
                param = { id : this.nomina.id, accion: 'RECALCULAR', tipo_reporte: "empleados", tipo_nomina : 'GENERAL', empleados : empleados, descargar:true }
            }

            let url = "calculo-nomina/calcular";
            const FileDownload = require("js-file-download");
            let nombre;
            //nombre = "recalculado.xlsx"
            nombre =  this.nomina.clave_tipo_nomina + "-" + this.nomina.descripcion + " - Periodo " + this.nomina.numero_periodo + ".xlsx";

            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const object1 = param;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object1,
                }).then(async function(response){
                    FileDownload(response.data, nombre);
                    await self.getEmpleados(self.nomina.id);
                    self.selected_general=[];
                    self.$refs.tablageneral.unselectAll();
                    self.loadingLottie =  false;
                })
                .catch(async (error) => {
                    self.$refs.tablageneral.unselectAll();
                    self.selected_general=[];
                    //console.log(decodeURIComponent(escape(error.response.headers["content-type"])));
                    //Notify.ErrorToast(decodeURIComponent(escape(error.response.headers["content-type"])));
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                    self.loadingLottie =  false;
                });
            } catch (err) {
                self.$refs.tablageneral.unselectAll();
                self.selected_general=[];
                Notify.ErrorToast("No se pudo realizar esta acción");
                self.loadingLottie =  false;
            }
        },
        async consultar(empleado){
            let self = this;
            this.isLoadingCalculoTabla = true;
            setTimeout(function(){ document.getElementById("btnModalCalculoTabla").click(); }, 100);
            let empleados = [{empleado_id: empleado.empleado_id}];
          
            let tipoConsulta = 'RECONSULTAR';
            let parametros = { id : this.nomina.id, accion: tipoConsulta, tipo_reporte: "empleados", tipo_nomina : 'GENERAL', empleados : empleados }
            await apiCalculoNomina.calcular(parametros).then((response) => {
                let formatEmpleados = response.data;

                if(formatEmpleados.length != 0){
                    self.totalEmpleados    = formatEmpleados.length;
                    formatEmpleados.forEach(empleado => {
                        empleado.itemsPercepciones  = [];
                        empleado.itemsDeducciones   = [];
                        empleado.itemsOtrosPagos    = [];
                        empleado.itemsInformativos  = [];

                        if(parseFloat(empleado.empleado.total_sueldo) != 0){
                            empleado.itemsPercepciones.push({
                                codigo : 'P1000',
                                nombre : 'Sueldo',
                                total  : empleado.empleado.total_sueldo
                            });
                        }
                        empleado.incidencias.forEach(incidencia =>{
                            switch (incidencia.codigo_incidencia.charAt(0)) {
                                case 'P':
                                    let complemento = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsPercepciones.push(complemento);
                                    }
                                    
                                    break;
                                case 'D':
                                    let complemento2 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsDeducciones.push(complemento2);
                                    }
                                    break;
                                case 'O':
                                    let complemento3 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsOtrosPagos.push(complemento3);
                                    }
                                    break;  
                                case 'I':
                                    let complemento4 = {
                                        codigo : incidencia.codigo_incidencia,
                                        nombre : incidencia.nombre_incidencia,
                                        total  : incidencia.total
                                    }
                                    if(parseFloat(incidencia.total) != 0){
                                        empleado.itemsInformativos.push(complemento4);
                                    }
                                    break;  
                                default:
                                    break;
                            }  
                        })

                        if(empleado.ajuste_mensual){
                            empleado.ajuste_mensual.forEach(incidencia =>{
                                switch (incidencia.codigo.charAt(0)) {
                                    case 'P':
                                        let complemento = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsPercepciones.push(complemento);
                                        }
                                        break;
                                    case 'D':
                                        let complemento2 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsDeducciones.push(complemento2);
                                        }
                                        break;
                                    case 'O':
                                        let complemento3 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsOtrosPagos.push(complemento3);
                                        }
                                        break;  
                                    case 'I':
                                        let complemento4 = {
                                            codigo : incidencia.codigo,
                                            nombre : incidencia.nombre,
                                            total  : incidencia.total
                                        }
                                        if(parseFloat(incidencia.total) != 0){
                                            empleado.itemsInformativos.push(complemento4);
                                        }
                                        break;  
                                    default:
                                        break;
                                }  
                            })
                        }
                        

                        if(parseFloat(empleado.subsidio) != 0){
                            empleado.itemsOtrosPagos.push({
                                codigo : 'O999',
                                nombre : 'Subsidio a entregar',
                                total  : empleado.subsidio
                            });
                        }
                        if(parseFloat(empleado.isr) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'D998',
                                nombre : 'ISR',
                                total  : empleado.isr
                            });
                        }
                        if(parseFloat(empleado.imss) != 0){
                            empleado.itemsDeducciones.push({
                                codigo : 'D999',
                                nombre : 'IMSS',
                                total  : empleado.imss
                            });
                        }
                    })
                }

                this.itemsEmpleados = formatEmpleados;
                this.isLoadingCalculoTabla = false;
                if(!response.valid){
                    Notify.Error("ADVERTENCIA","Uno o más empleados presentan problemas en el cálculo de su nómina", null, 'Enterado')
                }
                
                
            })
            .catch(err => {
                console.log(err)
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
                this.isSaving = false;
                this.cerrarModalTabla();
            });
        },
        cerrarModalTabla(){
            this.dialogCalculoTabla = false;    
        },

        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
        redondearNumero(numero){
            return Math.round(numero*100)/100
        },
        async getEmpleados(nominas_id) {
            let param = { 
                nomina_id : nominas_id
            };
            await apiCalculoNomina.getEmpleadosAcumuladoTimbre(param).then(response => {
                this.itemsEmpleadosTabla= response.acumulados;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los empleados");
                this.detallePrecalculo();
            });
        },
        detallePrecalculo(data){
            this.$session.remove("detallePrecalculo");
            this.$router.push('/calculoNomina');
        }
    },
    beforeCreate(){
        if(!this.$session.get('detallePrecalculo')){
            this.$router.push('/dashboard');
        }
    },
    created() {
        this.nomina = this.$session.get('detallePrecalculo');
        this.filters.nomina_id = this.nomina.id;
        this.urlAvatar = GetUrlImg.getURL();
        this.getEmpleados(this.nomina.id);
    },

    /**
     * @description updated(). Se ejecuta cada vez que hay un cambio en el Virtual DOM.
    */
    updated() {    
        
        document.querySelector('#tableScrollSimple thead tr th:nth-child(1)').style.width   = "115px";
        /* document.querySelector('#tableScrollSimple thead tr th:nth-child(4)').style.width   = "310px";
        document.querySelector('#tableScrollSimple thead tr th:nth-child(4)').style.paddingRight   = "8px";
        document.querySelector('#tableScrollSimple thead tr th:nth-child(4)').style.paddingLeft   = "8px"; */
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .btn-acciones:hover{
        color: #FFFFFF;    
    }
    .tituloAcciones{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #1E2245;
    }
    .btn-agrupar-por {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #1E2245;
        text-transform: none;
        height: 38px !important;
        min-width: 140px !important;
    }
    .menuClassAcciones{
        border-radius: 10px!important;
        margin-top:15px;
    }
    .tablaWidth{
        width: 100%;
        width: -moz-available;          
        width: -webkit-fill-available;  
        width: fill-available;
    }
    #tableScrollSimple tbody{
        max-height:none !important;
        min-width: 100% !important;
        background-color: #fff !important;
    }
    .tbl-child tbody td:first-child {
        padding-left: 35px !important;
        width: 320px;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
        padding-left: 15vh;
    }
    .textNeto{
        padding-left: 15vh;
    }
</style>